import React from 'react';
import "./AboutUs.css";
import aboutImg from "../../assets/about.png"
import {Fade} from "react-awesome-reveal";

export default function AboutUs() {
  return (
    <Fade triggerOnce={true} >
    <div className="aboutUs container" id="about">
      <div className="aboutUs-main">
        <div className="aboutUs-side1">
          <div className="aboutUs-header">
            <h2>
              Who Are We And What Are Our <span>Goals</span> ?
            </h2>
            <div className="aboutUs-para pt-2">
              <p>
              We are a pair of tech and business enthusiasts, each with over 7 years of industry experience, having graduated from the prestigious IITs of India. Over the past 7 years, we've worked with renowned MNCs in the tech space. With Alfred, we are taking a leap forward in our careers, aiming to revolutionize the tourism industry by leveraging technology. While other industries have embraced tech, the tourism sector still lags behind, and Alfred is our solution to bridge that gap and transform how the entire ecosystem operates.
              </p>
            </div>
        </div>
        </div>
        <div className="aboutUs-side2">
          <div className="aboutUs-img">
            <img src = {aboutImg} alt="error" />
          </div>
        </div>
      </div>
    </div>
    </Fade>
  )
}
