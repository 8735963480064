import React from 'react';
import "./Product.css";
// import Product1 from './Product1/Product1';
import Product2 from './Product2/Product2';
import Product3 from "./Product3/Product3"
 
export default function Product() {
  return (
    <div className='product' id="product">
      <div className='product-header'>
        <h1>Our <span>Products</span></h1>
      </div>
    
      <div className='products container mt-5'>
        {/* <Product1/> */}
        <Product2/>
        <Product3/>
      </div>
    </div>
  )
}
