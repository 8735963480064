import React from 'react'
import "./PageNotFound.css"

export default function PageNotFound() {
  return (
    <div className="page-not-found">
        <div className="pageNot-header">
            <h1>404 Page Not Found</h1>
        </div>
    </div>
  )
}
