import React from 'react';
import "./Navbar.css";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-scroll';
// import {Link as Link1} from "react-router-dom"
import logo from "../assets/logo.png";

export default function Navbar1() {
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" sticky="top">
        <Container>
            <Navbar.Brand as={Link} to="/">
                <img src={logo} className="img-fluid" alt="Error" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="ms-auto me-auto">
                    <Nav.Link  as={Link} to="home"  spy={true} smooth={true} offset={-100} duration={100} >Home</Nav.Link>
                    <Nav.Link  as={Link} to="product"  spy={true} smooth={true} offset={-100} duration={100} >Product</Nav.Link>
                    <Nav.Link  as={Link} to="launch"   spy={true} smooth={true} offset={-100} duration={100}>Launch</Nav.Link>
                    <Nav.Link  as={Link} to="about"  spy={true} smooth={true} offset={-100} duration={100} >About Us</Nav.Link>
                    <Nav.Link as={Link} to="contact"  spy={true} smooth={true} offset={-100} duration={100} >Contact</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
  )
}
