import React from 'react';
import "./Contact.css";
import contact from "../../assets/contact.png";
import {Link} from "react-router-dom";
import {Fade} from "react-awesome-reveal";

export default function Contact() {
  return (
    <Fade triggerOnce={true}>
    <div className="contact container" id="contact">
      <div className="contact-main">
        <div className="contact-side1">
          <div className="contact-header">
            <h2>
                Feel Free to<span> Contact Us</span>
            </h2>
            <div className="contact-para pt-2">
              <p>
              Join us for an interactive demo session where you'll get a firsthand experience of our latest product. During the session, we'll walk you through key features, showcase how the product works in real time, and explain its practical applications. This is a great opportunity to ask questions, gain insights, and understand how this solution can benefit you or your business. 
              </p>
            </div>
          </div>
          <div className="contact-btn">
          <Link to="https://docs.google.com/forms/d/e/1FAIpQLSfMdNOuWmgFd6ilio1jc9z_fvEl5AC5M8_nZwGpdHGNKQV5Iw/viewform" target="_blank"><button>Contact Now</button></Link>
          </div>

        </div>

        <div className="contact-side2">
          <div className="contact-img">
            <img src = {contact} alt="error" />
          </div>
        </div>
      </div>
    </div>
    </Fade>
  )
}
