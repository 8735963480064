import React, { useState, useEffect } from 'react';
import "./Launch.css";
import rocket from "../../assets/rocket.png"
import { JackInTheBox} from "react-awesome-reveal"

export default function Launch() {
  // Set initial state for days, hours, minutes, and seconds
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  // Define the target launch date (adjust this date for your actual launch)
  const targetDate = new Date('2024-10-30T00:00:00').getTime();

  useEffect(() => {
    // Function to update the countdown
    const countdown = () => {
      const now = new Date().getTime();
      const difference = targetDate - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        // Update the timeLeft state with the new values
        setTimeLeft({ days, hours, minutes, seconds });
      } else {
        // If the launch date is passed, stop the countdown
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    };

    // Update the countdown every second
    const intervalId = setInterval(countdown, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [targetDate]);

  return (
    <JackInTheBox triggerOnce={true}  >
    <div className="launch container" id="launch">
      <div className="launch-header">
        <h1>We're <span>Launching</span> Our Product Soon</h1>
      </div>
      <div className="launch-main">
        <div className="launch-side1">
          <div className="launch-date">
            <div className="launch-day">
              <div className='date-text'>{timeLeft.days}</div>
              <div className='date-title'>Days</div>
            </div>

            <div className="launch-hours">
              <div className='date-text'>{timeLeft.hours}</div>
              <div className='date-title'>Hours</div>
            </div>

            <div className="launch-minutes">
              <div className='date-text'>{timeLeft.minutes}</div>
              <div className='date-title'>Minutes</div>
            </div>

            <div className="launch-seconds">
              <div className='date-text'>{timeLeft.seconds}</div>
              <div className='date-title'>Seconds</div>
            </div>
          </div>
        </div>

        <div className="launch-side2">
            <div className="launch-side2-img">
                <img src={rocket} alt="error"/>
            </div>
        </div>

      </div>
    </div>
    </JackInTheBox>
  );
}
