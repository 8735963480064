import React from 'react'
import "./Product3.css";
import product3 from "../../../assets/product3.png"
import {  Slide } from 'react-awesome-reveal';

export default function Product3() {
  return (
    <Slide triggerOnce={true} direction='left' >
    <div className='product3'>
        <div className='product3-main'>
            <div className='product3-side2'>
                <img src={product3} alt="error" />
            </div>
            <div className='product3-side1'>
                <div className='product3-side1-header'>
                    <h2>User App</h2>
                    <div className='product3-side1-para'>
                        <p>
                        One stop solution for all travellers need. With the user app, people will be able to become part of the new age travel ecosystem. From staying in hotels to roam around the city, this app will enable users to make amazing choices during their trip.
                        </p>
                    </div>
                </div>
                <div className="product3-btn">
                    <button>Get the App Now</button>
                </div>
            </div>
        </div>
    </div>
    </Slide>
  )
}
