import React from 'react'
import "./Main.css"
import Home from '../Home/Home'
import Product from '../Product/Product'
import Launch from '../Launch/Launch'
import AboutUs from '../AboutUs/AboutUs'
import Contact from '../Contact/Contact'

export default function Main() {
  return (
    <div className='main'>
      <Home/>
      <Product/>
      <Launch/>
      <AboutUs/>
      <Contact/>
    </div>
  )
}
