import { Routes,Route } from "react-router-dom";
import Main from "./pages/Main/Main";
import Navbar1 from "./components/Navbar";
import Footer from "./components/Footer";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
 
function App() {
  return (
    <>
    <Navbar1/>
      <Routes>
        <Route path="/" element={<Main/>} />
        <Route path="*" element={<PageNotFound/>} />
      </Routes>
    <Footer/>
    </>
  );
}

export default App;
