import "./Footer.css"
import {Link} from "react-scroll";
// import {Link as Link1} from "react-router-dom"
// import { FaFacebookSquare } from "react-icons/fa";
// import { FaInstagramSquare } from "react-icons/fa";
// import { FaLinkedin } from "react-icons/fa6";
// import { FaTwitterSquare } from "react-icons/fa";
export default function Footer() {
  return (
    <div className="footer">

        <div className="footer-main">
            <div className="footer1">
                <div className="footer1-header">
                    <h2>Join Our Exclusive Platform Now</h2>
                    <p>
                    Join us for an interactive demo session where you'll get a firsthand experience of our latest product. During the session, we'll walk you through key features, showcase how the product works in real time, and explain its practical applications. This is a great opportunity to ask questions, gain insights, and understand how this solution can benefit you or your business.
                    </p>
                </div>
                {/* <div className="footer-icons">
                    <FaFacebookSquare size={30} style={{ color: "white" }}/>
                    <FaInstagramSquare size={30} color="white"/>
                    <FaLinkedin size={30} color="white"/>
                    <FaTwitterSquare size={30} color="white" />
                </div> */}
            </div>

            <div className="footer2">
                <div className="footer2-links">
                    <Link to="home"  spy={true} smooth={true} offset={-100} duration={100}>Home</Link>
                    <Link to="product"  spy={true} smooth={true} offset={-100} duration={100}>Product</Link>
                    <Link to="launch"  spy={true} smooth={true} offset={-100} duration={100}>Launch</Link>
                    <Link to="about"  spy={true} smooth={true} offset={-100} duration={100}>About Us</Link>
                    <Link to="contact"  spy={true} smooth={true} offset={-100} duration={100}   >Contact</Link>
                </div>
            </div>
        </div>
        <hr  />
        <div className="footer-rights">
            &copy; 2024 Copyright & Alfred Solutions
        </div>
    </div>
  )
}
