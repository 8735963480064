import React from 'react';
import "./Home.css";
import hero from "../../assets/hero2.png"
import {Fade} from "react-awesome-reveal"

export default function Home() {
  return (
    <Fade triggerOnce={true}  >
    <div className='home container' id="home">

      {/* Main */}
      <div className='home-main'>
        
        {/* Side 1 */}
        <div className='home-side1'>
          <div className='home-side1-header'>
            <h2>What We Are Doing ?</h2>
            <div className='home-para'>
              <p>
              Alfred is a cloud-based platform designed to digitally empower hotels, guests, and vendors by creating a connected and collaborative travel and tourism ecosystem. It offers a comprehensive solution aimed at boosting hotel revenue, streamlining hotel operations, delivering cutting-edge targeted marketing solutions, and enhancing the end-to-end travel experience for all stakeholders. 
              </p>
            </div>
          </div>
        </div>
        
        <div className='home-side2'>
          <div className='home-img'>
            <img src={hero} alt="Error" />
          </div>
        </div>
      </div>
    </div>
    </Fade>
  )
}
