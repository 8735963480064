import React from 'react';
import product2 from "../../../assets/product2.png";
import "./Product2.css";
import {  Slide } from 'react-awesome-reveal';

export default function Product2() {
  return (
    <Slide direction='right' triggerOnce={true}  >
    <div className='product2'>
        <div className='product2-main'>
            <div className='product2-side2'>
                <img src={product2} alt="error" />
            </div>
            <div className='product2-side1'>
                <div className='product2-side1-header'>
                    <h2>Management App</h2>
                    <div className='product2-side1-para'>
                        <p>
                        A one stop platform to manage all the guest related tasks in an efficient way. With management app hotel managers and owners can scale up their revenue, promote property in a much better way & provide upmost guest satisfaction.
                        </p>
                    </div>
                </div>
                <div className="product2-btn">
                    <button>Get the App Now</button>
                </div>
            </div>
        </div>
    </div>
    </Slide>
  )
}
